<template>
  <Home/>
</template>

<script setup>
import Home from './views/index.vue'

console.log("这是初相位官网");
console.log("你好呀，你又来看我了吗");
</script>

<style>
  #app{width: 100%;height: 100vh;}
</style>
