import { createRouter, createWebHashHistory  } from 'vue-router'
const routes = [
    {
      path: '/',
      redirect: '/home',
    },
    {
        path: '/text',
        component: () => import('@/views/text.vue'),
      },
    {
        path:'/download',
        component: () => import('@/views/download.vue'),
        meta: { show:'B'}
    },
    {
        path:'/download2',
        component: () => import('@/views/zxDownload.vue'),
        meta: { show:'C'}
    },
    {
        path:'/about',
        component: () => import('@/views/about.vue'),
    },
    {
        path: '/home',
        component: () => import('@/views/home.vue'),
    },
  ]

const router = createRouter({
    // hash
    history: createWebHashHistory(),  
    routes,
    scrollBehavior() {
        return { top: 0 }
     }
})
    
    export default router